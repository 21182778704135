import React, { useState, useEffect, useContext, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
  selectListSx,
  formSx,
  inputLabelSx,
  menuItemSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  settingsElement,
  toggle,
  image,
} from "../style/pages/toppers.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import dataJson from "../data/toppers.json";

import { StoreContext } from "../context/store-context";

function Toppers() {
  const indToppersImages = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { in: ["toppers", "toppers/readymade"] }
          name: { nin: "main" }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          relativeDirectory
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const containerToppers = useRef();

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [toppers, setToppers] = useState("");
  const [idToppers, setIdToppers] = useState("");
  const [color, setColor] = useState(null);
  const [isNumber, setIsNumber] = useState(false);
  const [numberTopper, setNumberTopper] = useState([]);
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Выбор из заготовленных топперов
  const handleTopper = (event) => {
    setIdToppers(event.target.value);
    dataJson
      .filter((item) => item.id === event.target.value)
      .forEach((item) => {
        setToppers(item.info);
        setCost(Number(item.price));
        if (item.number) {
          setIsNumber(true);
        } else {
          setIsNumber(false);
          setNumberTopper(null);
        }
      });
    setColor(null);
  };

  // Обновить состояние для цвета
  const handleColor = (event, newAlignment) => {
    setColor(newAlignment);
  };

  // Обновить состояние для цифр
  const handleNumber = (event, newAlignment) => {
    const numberQuantity = newAlignment.length;

    setNumberTopper(newAlignment);
    setCost(150 * numberQuantity);
  };

  // Обновить состояние для количества топпера
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор опор
  const addProduts = () => {
    const dataOrder = {
      toppers: toppers,
      isNumber: isNumber,
      numberTopper: numberTopper,
      color: color,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: { ...store.substrates },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
      frame: { ...store.frame },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      toppers: {
        bundle: [...store.toppers.bundle, dataOrder],
        totalPrice: store.toppers.totalPrice + dataOrder.totalAmount,
      },
    });

    setToppers("");
    setIdToppers("");
    setIsNumber(false);
    setNumberTopper([]);
    setColor(null);
    setCost(0);
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.toppers.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.toppers.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      toppers: {
        bundle: [...arrBundle],
        totalPrice: store.toppers.totalPrice - deductibleAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      screens: { ...store.screens },
      substrates: { ...store.substrates },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let q = quantity || 0;
    let c = cost || 0;

    setTotalAmount(q * c);
  }, [quantity, cost]);

  useEffect(() => {
    containerToppers.current.scrollTo(0, 0);
  }, [idToppers]);

  return (
    <main className="mainpage">
      <MetaTag title="Топперы | Wowmilota" />
      <motion.div
        layoutId="toppersContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
        ref={containerToppers}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        {!idToppers ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className={previewContainer}
          >
            {indToppersImages?.allFile?.nodes
              .filter((item) => item.relativeDirectory === "toppers")
              .map((img) => {
                const { childImageSharp } = img;
                const src = getImage(img);

                return (
                  <motion.div
                    onClick={() => setSelectedImg(src)}
                    onKeyDown={() => setSelectedImg(src)}
                    className={previewImage}
                    key={childImageSharp.id}
                    whileHover={{ scale: 0.98 }}
                  >
                    <GatsbyImage
                      image={src}
                      alt="Фотографии топперов"
                      className={image}
                    />
                  </motion.div>
                );
              })}
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className={previewContainer}
          >
            {indToppersImages?.allFile?.nodes
              .filter(
                (item) =>
                  item.name === String(idToppers) &&
                  item.relativeDirectory === "toppers/readymade"
              )
              .map((img) => {
                const { childImageSharp } = img;
                const src = getImage(img);

                return (
                  <motion.div
                    onClick={() => setSelectedImg(src)}
                    onKeyDown={() => setSelectedImg(src)}
                    className={previewImage}
                    key={childImageSharp.id}
                    whileHover={{ scale: 0.995 }}
                  >
                    <GatsbyImage
                      image={src}
                      alt="Фотографии топперов"
                      className={image}
                    />
                  </motion.div>
                );
              })}
          </motion.div>
        )}

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Топперы</h2>
                </div>
                <p>
                  Современные стильные топперы и различные элементы декора из
                  акрила украсят ваш торт/десерт и станут отличным завершенем
                  общей задумки.
                  <br />
                  На нашем сайте мы представили ряд топперов на все
                  торжественные случаи.
                  <br />
                  Но также мы изготавливаем топперы по индивидуальным заказам,
                  будь это свадебный топпер с инициалами, именной топпер на
                  детский день рождения, или сложный многоуровневый топпер с
                  использовнием разных материалов.
                </p>
              </div>

              <h3 className={titleSettings}>
                Выберите готовый или кратко опишите индивидуальный топпер
              </h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: idToppers
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Готовый топпер</h3>
                  <FormControl variant="standard" fullWidth sx={formSx}>
                    <InputLabel id="toppers-toppers-label" sx={inputLabelSx}>
                      Выберите топпер
                    </InputLabel>
                    <Select
                      sx={selectListSx}
                      labelId="toppers-toppers-label"
                      id="toppersToppers"
                      value={idToppers}
                      label="Выберите топпер"
                      onChange={handleTopper}
                    >
                      {dataJson.map((item, index) => {
                        const { info, id } = item;

                        return (
                          <MenuItem
                            sx={menuItemSx}
                            value={id}
                            key={`topper${index}`}
                          >
                            {info}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
                >
                  <h3>Индивидуальный топпер</h3>
                  <p>Вы можете заказать индивидуальный топпер.</p>
                  <p>Контакты для связи с менеджером:</p>
                  <a href="https://wa.me/79029111546">WhatsApp</a>
                  <a href="https://wa.me/79029111546"> +7 (902) 911 15 46</a>
                </div>
              </div>

              {idToppers && (
                <>
                  <h3 className={titleSettings}>
                    Параметры для готовых топперов
                  </h3>

                  <div className={settingsList}>
                    <div
                      className={settingsElement}
                      style={{
                        backgroundColor: color
                          ? "rgba(255, 255, 255, 0.15)"
                          : "rgba(255, 255, 255, 0.05)",
                      }}
                    >
                      <h3>Цвет</h3>
                      <ToggleButtonGroup
                        size="medium"
                        value={color}
                        exclusive
                        onChange={handleColor}
                        sx={toggleContainerSx}
                      >
                        <ToggleButton
                          value="Серебро"
                          key={`colorSilver`}
                          sx={toggleButtonSx}
                        >
                          Серебро
                        </ToggleButton>
                        <ToggleButton
                          value="Золото"
                          key={`colorGold`}
                          sx={toggleButtonSx}
                        >
                          Золото
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>

                    {isNumber && (
                      <div
                        className={settingsElement}
                        style={{
                          backgroundColor:
                            numberTopper?.length > 0
                              ? "rgba(255, 255, 255, 0.15)"
                              : "rgba(255, 255, 255, 0.05)",
                        }}
                      >
                        <h3>Номер</h3>
                        <ToggleButtonGroup
                          size="medium"
                          value={numberTopper}
                          onChange={handleNumber}
                          sx={toggleContainerSx}
                        >
                          {Array(10)
                            .fill()
                            .map((item, i) => {
                              return (
                                <ToggleButton
                                  value={i}
                                  key={`numberTopper${i}`}
                                  sx={toggleButtonSx}
                                >
                                  {i}
                                </ToggleButton>
                              );
                            })}
                        </ToggleButtonGroup>
                      </div>
                    )}
                  </div>
                </>
              )}

              <h3 className={titleSettings}>Количество топперов</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="toppersQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.toppers.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.toppers.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.toppers}</p>
                        {item.isNumber && (
                          <p>
                            Цифры: {item.numberTopper.map((item) => `${item} `)}
                          </p>
                        )}
                        {item.color && <p>Цвет: {item.color}</p>}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>{store?.toppers.bundle.length}</p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.toppers.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.toppers}</p>
                          {item.isNumber && (
                            <p>
                              Цифры:{" "}
                              {item.numberTopper.map((item) => `${item} `)}
                            </p>
                          )}
                          {item.color && <p>Цвет: {item.color}</p>}
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 && color ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.toppers.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Toppers;
